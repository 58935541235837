// Import dependencies
import React from "react";
import {useStaticQuery, graphql, Link, navigate} from "gatsby";
import { Router } from "@reach/router";
import Img from "gatsby-image";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import SectionSeparator from "../components/separators/sectionSeparator";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import DepartmentDrawer from "../components/cateogry-drawer/department-drawer";
import SearchBar from "../components/search-bar/searchBar";
import DepartmentContent from "../components/department/departmentContent";
import BrandNotFound from "../components/department/brandNotFound";

import {connect} from "react-redux";

import { getFirebase } from "../api/firebase";

// Import styless
import "./index.css";


/*
    Index page component
*/
  class Department extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        popupOpen: false,
        departments: ["gadgets", "kitchen-home", "books", "clothes", "cookware"],
        departmentName: "",
        departmentTitle: "",
        departmentAvailable: false,
        replaceArray: [],
        brandTitle: []
      };
    }
    componentDidMount() {

      if (this.state.departments.indexOf(this.props.params['*'].replace("/brand-not-found", "")) == -1) {
        navigate("/404");
      } else {
        this.setState({departmentAvailable: true});
        let departmentName = this.props.params['*'].replace("/brand-not-found", "");
        let departmentTitle = this.props.params['*'].replace("/brand-not-found", "").replace("-", " & ")
                          .split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
        let detailsProduct = [];
        let replaceArray = [];

        if (departmentName == "gadgets" || departmentName == "kitchen-home") {
          detailsProduct = Object.values(this.props.store.productNameDetails)
          .filter((product) => {
            return product.Product.toLowerCase().includes("other")
            && product["fixed-rate"] != ""
            && product.Department ==  "Electronics"
            &&
            (departmentName == "gadgets" ?
              product.Category != "Kitchen Appliances" && product.Category != "Home Appliances" :
                product.Category == "Kitchen Appliances" || product.Category == "Home Appliances"
            )
          });
        } else {
          detailsProduct = Object.keys(this.props.store.categoryTree[departmentTitle]);
        }


           replaceArray = detailsProduct
            .map((product) => {
              if (departmentName == "gadgets" || departmentName == "kitchen-home") {
                return product.SubCategory.toUpperCase()
              } else {
                return product.toUpperCase();
              }
            })
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            });

            if (departmentName == "clothes" || departmentName == "books" || departmentName == "cookware") {
              detailsProduct = Object.values(this.props.store.productName);
            }

            const brandTitle =
              detailsProduct
              .filter((product) => {
                if (departmentName == "gadgets" || departmentName == "kitchen-home") {
                  return product
                } else {
                  return product.Department == departmentTitle && !(product.Name.startsWith("OTHER"))
                }
              })
              .map((product) => {
                if (departmentName == "gadgets" || departmentName == "kitchen-home") {
                  return product.Product.replace("OTHER ", "")
                } else {
                  return product
                }
              })
              .map((product, index) => {
                let productNameDetails;
                if (departmentName == "gadgets" || departmentName == "kitchen-home") {
                  productNameDetails = product;
                } else {
                  productNameDetails = product.Name;
                }
                replaceArray
                  .sort((a, b) => {
                    const specialLengthA = a.includes('-') ? 1 : 0;
                    const specialLengthB = b.includes('-') ? 1 : 0;
                    return (b.split(' ').length + specialLengthB) - (a.split(' ').length + specialLengthA)
                  })
                  .forEach((subCategory) => {
                    productNameDetails = productNameDetails
                    .replace(" " + subCategory.toUpperCase(), "")
                    .replace(subCategory.toUpperCase(), "");
                  });
                return productNameDetails;
              })
              .filter((value, index, self) => {
                return self.indexOf(value) === index && value != "";
              });

              this.setState({
                departmentName: departmentName,
                departmentTitle: departmentTitle,
                replaceArray: replaceArray,
                brandTitle: brandTitle,
              });

      }

    }


    render() {
      if (this.state.departmentAvailable) {
          return (
        <Layout noWhatsapp>
        <CategoryDrawer/>
            <SEO
                title="Home"
                description="On-demand buy-back and recycling for used electronics, books, and clothes."
            />

            <SearchBar/>

            <Router>
              <BrandNotFound path="/department/:title/brand-not-found"
              departmenTitle={this.state.departmentTitle}
              categories={this.state.replaceArray.sort()}
              />
              <DepartmentContent
                path="/department/:title"

                departmenTitle={this.state.departmentTitle}
                brands={this.state.brandTitle.sort()}
                categories={this.state.replaceArray.sort()}
              />
            </Router>

        </Layout>
    );
  } else {
    return(<></>)
  }
  }
};


function readProductsList(storeState) {
    let store = {
        productName: storeState.product.productNamesById,
        categoryTree: storeState.product.productCategoryTree,
        productNameDetails: storeState.product.productsIndexedById,

    };
    return {store};
}

export default connect(readProductsList)(Department);
